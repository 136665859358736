import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  konzerte: [],
  loaded: false,
  loading: false,
  error: null
}

// getters
const getters = {
  konzerte (state) {
    return state.konzerte
  },
  konzert: (state) => (id) => {
    return state.konzerte.find(konzert => konzert.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadKonzerte ({commit}) {
    commit('setLoading', true)
    console.log('Konzerte laden...')
    firebase.firestore().collection('uni').doc('bonn').collection('konzerte')
    .onSnapshot(snapshot => {
      const konzerte = []
      snapshot.forEach(doc => {
        konzerte.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedKonzerte', konzerte)
        commit('setLoading', false)
        commit('setLoaded', true)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createKonzert ({commit, getters}, payload) {
    console.log(payload)
    firebase.firestore().collection('uni').doc('bonn').collection('konzerte').add(payload)
      .catch((error) => {
        console.log(error)
      })
  },
  deleteKonzertData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('uni').doc('bonn').collection('konzerte').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteKonzert', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateKonzertData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('uni').doc('bonn').collection('konzerte').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateKonzert', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedKonzerte (state, payload) {
    state.konzerte = payload
  },
  createKonzert (state, payload) {
    state.konzerte.push(payload)
  },
  updateKonzert (state, payload) {
    const konzert = state.konzerte.find(konzert => {
      return konzert.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        konzert[key] = payload[key]
      }
    })
  },
  deleteKonzert (state, payload) {
    let array = state.konzerte
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.konzerte = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
