import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  ensembles: [],
  loaded: false,
  loading: false,
  error: null
}

// getters
const getters = {
  ensembles (state) {
    return state.ensembles
  },
  ensemble: (state) => (id) => {
    return state.ensembles.find(ensemble => ensemble.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadEnsembles ({commit}) {
    commit('setLoading', true)
    console.log('Ensembles laden...')
    firebase.firestore().collection('uni').doc('bonn').collection('ensembles')
    .onSnapshot(snapshot => {
      const ensembles = []
      snapshot.forEach(doc => {
        ensembles.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedEnsembles', ensembles)
        commit('setLoading', false)
        commit('setLoaded', true)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createEnsemble ({commit, getters}, payload) {
    console.log(payload)
    firebase.firestore().collection('uni').doc('bonn').collection('ensembles').add(payload)
      .catch((error) => {
        console.log(error)
      })
  },
  deleteEnsembleData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('uni').doc('bonn').collection('ensembles').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteEnsemble', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateEnsembleData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('uni').doc('bonn').collection('ensembles').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateEnsemble', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedEnsembles (state, payload) {
    state.ensembles = payload
  },
  createEnsemble (state, payload) {
    state.ensembles.push(payload)
  },
  updateEnsemble (state, payload) {
    const ensemble = state.ensembles.find(ensemble => {
      return ensemble.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        ensemble[key] = payload[key]
      }
    })
  },
  deleteEnsemble (state, payload) {
    let array = state.ensembles
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.ensembles = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
