<template>
  <v-app class="bg-grey-lighten-4">
    <functionBar />
    <headerBar />
    
    <v-main class="ma-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import headerBar from './components/ui/headerBar.vue'
import functionBar from './components/ui/functionBar.vue'
export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    headerBar,
    functionBar
  },
  computed: {
    // 
  }
}
</script>
