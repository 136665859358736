<template>
  <v-app-bar height="120">
    <template v-slot:prepend>
      <v-img src="@/assets/logo_cmb_schwarz.png" height="120" width="200" contain></v-img>
      <!-- <v-btn to="/">Besucher Übersicht</v-btn>
      <v-btn to="/buchen">Buchen</v-btn>
      <v-btn to="/storno">Storno</v-btn>
      <v-btn to="/ensemble">Ensemble</v-btn>
      <v-btn to="/forum">Forum</v-btn> -->
    </template>
    <v-app-bar-title>Notengenerator</v-app-bar-title>

    <template v-slot:append>
      
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'headerBar',
  data: () => ({
    showNavBar: false,
  }),
  computed: {
    // 
  }
}
</script>
