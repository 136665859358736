import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import Vue from 'vue'

// initial state
const state = {
  user: null,
  userAccount: null,
  loading: false,
  error: null
}

// getters
const getters = {
  user (state) {
    return state.user
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  signUserUp ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          firebase.firestore().collection('vereine').doc(Vue.prototype.$vars.verbindung).collection('kontakte').where('email', '==', payload.email).get()
          .then(doc => {
            doc.forEach(item => {
              let updateObj = {
                anmeldeID: user.user.uid
              }
              firebase.firestore().collection('vereine').doc(Vue.prototype.$vars.verbindung).collection('kontakte').doc(item.id).update(updateObj)
              .then(() => {
                firebase.auth().currentUser.sendEmailVerification()
              })
              .catch(error => {
                commit('setError', error)
              })
            })
          })
          .catch(err => {
            commit('setError', err)
          })
          const newUser = {
            id: user.uid
          }
          commit('setUser', newUser)
          return newUser
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserIn ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    console.log('signUserIn')
    console.log(payload)
    firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          console.log(user)
          commit('setLoading', false)
          commit('setUser', user.user)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  autoSignIn ({commit}, payload) {
    console.log('autoSignIn')
    commit('setUser', payload)
  },
  logout ({commit}) {
    console.log('logout')
    firebase.auth().signOut()
    commit('setUser', null)
  },
  clearError ({ commit }) {
    commit('clearError')
  }
}

// mutations
const mutations = {
  setUser (state, payload) {
    state.user = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
