<template>
  <v-system-bar height="52">
    <v-row class="px-4">
      <v-col cols="12" class="text-right">
        <v-menu v-if="currentUser" left bottom offset-y max-width="300px" class="mb-0">
          <template v-slot:activator="{ props }">
            <v-btn variant="outlined" color="textMedium" rounded="lg" v-bind="props" :class="props['aria-expanded'] === 'true' ? 'rounded-b-0' : 'rounded-lg'" class="px-2">
              {{ currentUser.email }}
              <v-icon size="28" class="ml-2">account_circle</v-icon>
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>

          <v-list class="ma-0 pa-0">
            <v-list-item><v-list-item-title>Angemeldet als:<br>{{ currentUser.displayName
                }}</v-list-item-title></v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="onLogout">
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else variant="flat" to="/login">Login</v-btn>
      </v-col>
    </v-row>
  </v-system-bar>
</template>

<script>
export default {
  name: 'headerBar',
  data: () => ({
    showNavBar: false,
  }),
  computed: {
    currentUser() {
      return this.$store.state.user.user
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('user/logout')
      this.$router.push('/login')
    }
  }
}
</script>
