import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'
// import 'firebase/compat/storage'
import 'firebase/compat/functions'
// import 'firebase/compat/messaging'

var firebaseConfig = {
  apiKey: "AIzaSyAX31wY3dFjO7X_nHVNN3hvZfLU1qBq2hw",
  authDomain: "uni-musik.firebaseapp.com",
  projectId: "uni-musik",
  storageBucket: "uni-musik.appspot.com",
  messagingSenderId: "1081611487450",
  appId: "1:1081611487450:web:f6ac4d2951731b041afde3",
  measurementId: "G-Q2GY6TDTSH"
}

firebase.initializeApp(firebaseConfig)

export default firebase