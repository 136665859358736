import { createStore } from 'vuex';

import user from './modules/user.js'
import konzertorte from './modules/konzertorte'
import ensembles from './modules/ensembles'
import konzerte from './modules/konzerte'

const store = createStore({
  modules: {
    user,
    konzertorte,
    ensembles,
    konzerte,
  },
})


export default store;
