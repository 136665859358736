import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  konzertorte: [],
  loaded: false,
  loading: false,
  error: null
}

// getters
const getters = {
  konzertorte (state) {
    return state.konzertorte
  },
  konzertort: (state) => (id) => {
    return state.konzertorte.find(konzertort => konzertort.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadKonzertorte ({commit}) {
    commit('setLoading', true)
    console.log('Konzertorte laden...')
    firebase.firestore().collection('uni').doc('bonn').collection('konzertorte')
    .onSnapshot(snapshot => {
      const konzertorte = []
      snapshot.forEach(doc => {
        konzertorte.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedKonzertorte', konzertorte)
        commit('setLoading', false)
        commit('setLoaded', true)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createKonzertort ({commit, getters}, payload) {
    console.log(payload)
    firebase.firestore().collection('uni').doc('bonn').collection('konzertorte').add(payload)
      .catch((error) => {
        console.log(error)
      })
  },
  deleteKonzertortData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('uni').doc('bonn').collection('konzertorte').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteKonzertort', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateKonzertortData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('uni').doc('bonn').collection('konzertorte').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateKonzertort', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedKonzertorte (state, payload) {
    state.konzertorte = payload
  },
  createKonzertort (state, payload) {
    state.konzertorte.push(payload)
  },
  updateKonzertort (state, payload) {
    const konzertort = state.konzertorte.find(konzertort => {
      return konzertort.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        konzertort[key] = payload[key]
      }
    })
  },
  deleteKonzertort (state, payload) {
    let array = state.konzertorte
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.konzertorte = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
