import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    name: "Notengenerator",
    component: () => import("../pages/notengenerator")
  },
  {
    path: "/besucher",
    name: "Home",
    component: () => import("../pages/besucher")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/user/login")
  },
  {
    path: "/passwortvergessen",
    name: "passwortvergessen",
    component: () => import("../pages/user/passwortVergessen")
  },
  {
    path: "/buchen",
    name: "Buchen",
    component: () => import("../pages/buchung")
  },
  {
    path: '/storno',
    name: 'Storno',
    component: () => import("../pages/storno"),
    // beforeEnter: authGuard,
  },
  {
    path: '/ensemble',
    name: 'Ensemble',
    component: () => import("../pages/ensemble"),
    // beforeEnter: authGuard,
  },
  {
    path: '/forum',
    name: 'Forum',
    component: () => import("../pages/forum/index"),
    // beforeEnter: authGuard,
  },
  {
    path: '/forum/ensembles',
    name: 'Ensembles',
    component: () => import("../pages/forum/ensembles"),
    // beforeEnter: authGuard,
  },
  {
    path: '/forum/konzerte',
    name: 'Konzerte',
    component: () => import("../pages/forum/konzerte"),
    // beforeEnter: authGuard,
  },
  {
    path: '/forum/konzertorte',
    name: 'Konzertorte',
    component: () => import("../pages/forum/konzertorte"),
    // beforeEnter: authGuard,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/', // Weiterleitung auf die Startseite
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;